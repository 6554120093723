import styles from "./text_table.module.css";

import {useMemo, useState} from "react";
import {Duration} from "luxon";
import CreatableSelect from "react-select/creatable";

import {Editor} from "../editor/editor";
import {TextTable} from "./text_table";
import slate2html from "../editor/serialize";
import {mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import {mdiArrowCollapseUp} from "@mdi/js";


export const Row = ({isFirst, mergeUp, fragment, updateFragment, speakerOptions, tagsOptions, labelTypes, setLabelTypes}) => {
    const [expanded, setExpanded] = useState(false);

    const createOption = (label) => ({
      label,
      value: label.toLowerCase(),
    });

    const text = useMemo(() => fragment?.text?.map((node, j) => slate2html(node, j, "", {})), [fragment?.text]);

    return (
        <div className={styles.row}>
          <div className={styles.time}>
            <div style={{cursor: "pointer"}} onClick={() => setExpanded(!expanded)}>{expanded ? "V" : ">"}</div>
            <div>{Duration.fromMillis(fragment.time * 1000).toFormat('hh:mm:ss')}</div>
          </div>
          <div className={styles.select}>
              <CreatableSelect options={speakerOptions}
                               isClearable
                               value={fragment.speaker && createOption(fragment.speaker)}
                               onChange={o => updateFragment({speaker: o && o.value})}
                               onCreateOption={v => updateFragment({speaker: v})}/>
          </div>
          <div>
            {expanded ? <Editor uuid={fragment.uuid}
                                labelTypes={labelTypes}
                                setLabelTypes={setLabelTypes}
                                value={fragment?.text}
                                onChange={v => v !== fragment.text && updateFragment({text: v})}/> :
                text
            }
          </div>
          <div>
            {!expanded && <div style={{marginTop: "1rem"}}>
              {!isFirst && <span onClick={mergeUp} title="Объединить с предыдущим фрагментом"><Icon path={mdiArrowCollapseUp} size={1}/></span>}
            </div>}
            {expanded ? <CreatableSelect isMulti
                                         options={tagsOptions}
                                         value={fragment.tags.map(createOption)}
                                         onChange={o => updateFragment({tags: o.map(v => v.value)})}/> :
                fragment.tags.join(", ")}
          </div>
      </div>
    );
}

import Axios from 'axios';
import qs from "query-string";
import {useHistory} from "react-router";
import {useMemo} from "react";
import {toast} from "react-toastify";
import {ValidationError} from "yup";
import {QueryClient, useQueryClient} from "react-query";
import {useLocation} from "react-router-dom";


const axios = Axios.create({
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'none'}),
});


export const useApiClient = () => {
    const history = useHistory();
    const location = useLocation();

    const processError = (err, restricted) => {
        if (restricted && err.response && err.response.status === 401 &&
            !["/signin", "/signup", "/forgot", "/reset_password", "/corpora"].includes(location.pathname)) {
            toast.warn('Необходимо выполнить вход!');
            history.push('/signin');
        } else if (err.response && err.response.status === 403) {
            toast.warn(err.response.data.detail);
        } else if (err.response && err.response.status === 422) {
            const errors = err.response.data.detail.map(d => ({[d.loc[d.loc.length - 1]]: d.msg}))
            throw new ValidationError(errors);
        } else if (err.response && err.response.status === 500) {
            toast.error(err.response.data);
        }
        throw (err);
    }

    const queryClient = useQueryClient();

    const api = useMemo(() => ({
        queryClient,
        get: async (url, {restricted=true, params, ...options}={}) => {
            const headers = {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
            };

            try {
                return await axios.get(url, {params, headers});
            } catch (err) {
                processError(err, restricted);
            }
        },

        post: async (url, data, {restricted=true, ...options}={}) => {
            const headers = {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
            };

            try {
                return await axios.post(url, data, {headers});
            } catch (err) {
                processError(err, restricted);
            }
        },

        put: async (url, data, {restricted=true, ...options}={}) => {
            const headers = {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
            };

            try {
                return await axios.put(url, data, {headers});
            } catch (err) {
                processError(err, restricted);
            }
        },

        patch: async (url, data, {restricted=true, ...options}={}) => {
            const headers = {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
            };

            try {
                return await axios.patch(url, data, {headers});
            } catch (err) {
                processError(err, restricted);
            }
        },

        delete: async (url, data, {restricted=true, ...options}={}) => {
            const headers = {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
            };

            try {
                return await axios({
                    method: 'DELETE',
                    url: url,
                    data: data,
                    headers: headers,
                })
            }
            catch (err) {
                processError(err, restricted);
            }
        },
    }), [queryClient]);

    return api;
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import assign from 'object-assign';

export function callIfExists(func, ...args) {
    return (typeof func === 'function') && func(...args);
}

export function hasOwnProp(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function uniqueId() {
    return Math.random().toString(36).substring(7);
}

export const cssClasses = {
    menu: 'react-contextmenu',
    menuVisible: 'react-contextmenu--visible',
    menuWrapper: 'react-contextmenu-wrapper',
    menuItem: 'react-contextmenu-item',
    menuItemActive: 'react-contextmenu-item--active',
    menuItemDisabled: 'react-contextmenu-item--disabled',
    menuItemDivider: 'react-contextmenu-item--divider',
    menuItemSelected: 'react-contextmenu-item--selected',
    subMenu: 'react-contextmenu-submenu'
};

export const store = {};

export const canUseDOM = Boolean(
    typeof window !== 'undefined' && window.document && window.document.createElement
);

export const MENU_SHOW = 'REACT_CONTEXTMENU_SHOW';
export const MENU_HIDE = 'REACT_CONTEXTMENU_HIDE';


export function dispatchGlobalEvent(eventName, opts, target = window) {
    // Compatibale with IE
    // @see http://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
    let event;

    if (typeof window.CustomEvent === 'function') {
        event = new window.CustomEvent(eventName, { detail: opts });
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, false, true, opts);
    }

    if (target) {
        target.dispatchEvent(event);
        assign(store, opts);
    }
}

export function showMenu(opts = {}, target) {
    dispatchGlobalEvent(MENU_SHOW, assign({}, opts, { type: MENU_SHOW }), target);
}

export function hideMenu(opts = {}, target) {
    dispatchGlobalEvent(MENU_HIDE, assign({}, opts, { type: MENU_HIDE }), target);
}


export default class MenuItem extends Component {
    static propTypes = {
        attributes: PropTypes.object,
        children: PropTypes.node,
        className: PropTypes.string,
        data: PropTypes.object,
        disabled: PropTypes.bool,
        divider: PropTypes.bool,
        onClick: PropTypes.func,
        onMouseLeave: PropTypes.func,
        onMouseMove: PropTypes.func,
        onMouseDown: PropTypes.func,
        preventClose: PropTypes.bool,
        selected: PropTypes.bool
    };

    static defaultProps = {
        attributes: {},
        children: null,
        className: '',
        data: {},
        disabled: false,
        divider: false,
        onClick() { return null; },
        onMouseMove: () => null,
        onMouseLeave: () => null,
        preventClose: false,
        selected: false
    };

    handleClick = (event) => {
        if (event.button !== 0 && event.button !== 1) {
            event.preventDefault();
        }
        event.preventDefault();

        if (this.props.disabled || this.props.divider) return;

        callIfExists(
            this.props.onClick,
            event,
            assign({}, this.props.data, store.data),
            store.target
        );

        if (this.props.preventClose) return;

        hideMenu();
    }

    render() {
        const {
            attributes,
            children,
            className,
            disabled,
            divider,
            selected
        } = this.props;

        const menuItemClassNames = cx(
            className,
            cssClasses.menuItem,
            attributes.className,
            {
                [cx(cssClasses.menuItemDisabled, attributes.disabledClassName)]: disabled,
                [cx(cssClasses.menuItemDivider, attributes.dividerClassName)]: divider,
                [cx(cssClasses.menuItemSelected, attributes.selectedClassName)]: selected
            }
        );

        return (
            <div
                {...attributes} className={menuItemClassNames}
                role='menuitem' tabIndex='-1' aria-disabled={disabled ? 'true' : 'false'}
                aria-orientation={divider ? 'horizontal' : null}
                ref={(ref) => { this.ref = ref; }}
                onMouseMove={this.props.onMouseMove} onMouseLeave={this.props.onMouseLeave}
                onTouchEnd={this.handleClick} onMouseDown={this.handleClick}>
                {divider ? null : children}
            </div>
        );
    }
}

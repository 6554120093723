import 'react-toastify/dist/ReactToastify.css';

import './App.css';

import {BrowserRouter} from "react-router-dom";
import {Redirect, Route, Switch} from "react-router";
import {QueryClient, QueryClientProvider} from "react-query";
import {ToastContainer} from "react-toastify";

import {Project} from "./components/project/project";
import {ProjectList} from "./components/project_list/project_list";
import {CurrentUserProvider} from "./user";
import {ForgotForm, ResetForm, SigninForm, SignupForm} from "./auth";
import {Groups} from "./components/groups/groups";
import {Corpora} from "./components/corpora/corpora";


const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CurrentUserProvider>
            <Switch>
                <Route path='/signup'>
                    <SignupForm/>
                </Route>
                <Route path='/signin'>
                    <SigninForm/>
                </Route>
                <Route path='/forgot'>
                    <ForgotForm/>
                </Route>
                <Route path='/reset_password'>
                    <ResetForm/>
                </Route>
                <Route path='/projects/:id' exact component={Project} />
                <Redirect to='/groups' from='/' exact />
                <Route path='/groups' exact component={Groups} />
                <Route path='/groups/:group_id' exact component={ProjectList} />
                <Route path='/corpora' exact component={Corpora} />
            </Switch>
          </CurrentUserProvider>
        </BrowserRouter>
      </QueryClientProvider>
      <ToastContainer/>
    </div>
  );
}

export default App;

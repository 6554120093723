import CreatableSelect from "react-select/creatable";
import {useMemo} from "react";
import {useQuery} from "react-query";

import {useApiClient} from "../../api";

import styles from './transcriber.module.css';


export const FeatureSelect = ({features, setFeatures}) => {
  const api = useApiClient();
  const features_query = useQuery(['feature'], () => api.get(`/api/feature`));

  const createOption = (label) => ({
    label,
    value: label.toLowerCase(),
  });

  const featuresOptions = useMemo(() => (features_query.data?.data || []).map(createOption), [features_query.data]);

  return (
      <div className={styles.features}>
        <div className={styles.header}>Социобиологические характеристики</div>
        <div className={styles.select}>
        <CreatableSelect isMulti
                         options={featuresOptions}
                         value={features.map(createOption)}
                         onChange={o => setFeatures(o.map(v => v.value))}/>
        </div>
      </div>
  );
}

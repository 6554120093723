import styles from './project.module.css';

import PropTypes from 'prop-types';
import {useParams} from "react-router";
import {useMutation, useQuery} from "react-query";

import {useApiClient} from "../../api";
import {Transcriber} from "../transcriber/transcriber";
import {createContext, useContext} from "react";

export const ProjectContext = createContext();

const ProjectProvider = ({children}) => {
  const {id} = useParams();
  const api = useApiClient();

  const projectQuery = useQuery(['project', parseInt(id)], () => api.get(`/api/project/${id}`));

  return <ProjectContext.Provider value={{project: projectQuery.data?.data}}>
    {children}
  </ProjectContext.Provider>
}

export const Project = ({...props}) => {
  const {id} = useParams();
  const api = useApiClient();

  const projectQuery = useQuery(['project', parseInt(id)], () => api.get(`/api/project/${id}`));
  const project = projectQuery.data?.data;

  const {mutateAsync: save} = useMutation((data) =>
      api.patch(`/api/project/${id}`, {transcription: data}), {
      onSuccess: (resp) => {
          api.queryClient.setQueryData(['project', resp.data.id], resp);
      }
  });

  const recognize = () => api.post(`/api/project/${id}/recognize`)
      .then((resp) => api.queryClient.invalidateQueries(['project', parseInt(id)]));

  return (
      <div className={styles.project}>
        <ProjectProvider>
          {project ? <Transcriber src={project.src_public}
                                  initial={project.transcription}
                                  duration={project.duration}
                                  name={project.name}
                                  project={project}
                                  recognize={recognize}
                                  save={save}/> : null}
        </ProjectProvider>
      </div>
  );
};

Project.propTypes = {
};

Project.defaultProps = {
};

import React, {useCallback, useEffect, useState} from "react";
import {ErrorMessage, Field} from "formik";


export const ErrorView = ({children}) => (
    <span className="error">{children}</span>
);


export const Checkbox = ({field, form: {setFieldValue}, label}) => {
    const onClick = useCallback(() => setFieldValue(field.name, !field.value),
        [setFieldValue, field.name, field.value]);

    return (
        <>
            <input
                {...field}
                type="checkbox"
                checked={field.value}
                onClick={onClick}
            />
            <label htmlFor='living' onClick={onClick}>{label}</label>
        </>
    );
}


export const InputField = ({name, label, ...props}) => {
    return (
        <div className='col-12 col-12-mobilep'>
            <label htmlFor={name}>{label}</label>
            <Field name={name} type='text' {...props} />
            <ErrorMessage component={ErrorView} name={name}/>
        </div>
    )
}

import {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import {mdiPlayPause, mdiPlusCircle} from '@mdi/js';
import {Duration} from "luxon";
import {Timescale} from "../timescale/timescale";

import styles from './player.module.css';


export const Player = ({project, fragments, duration, name, addMarker, selectedFragment, setSelectedFragment, buttons, ...props}) => {
  const [audioRef, setAudioRef] = useState();
  const [currentPos, setCurrentPos] = useState();

  const fragmentClickHandler = useCallback((i) => {
      setSelectedFragment && setSelectedFragment(i);
  }, [setSelectedFragment]);

  useEffect(() => {
    if (audioRef) {
        const ontimeupdateCallback = e => setCurrentPos(audioRef.currentTime);
        audioRef.ontimeupdate = ontimeupdateCallback;
        return () => audioRef.ontimeupdate = undefined;
    }
  }, [audioRef]);

  const pointsInPercents = useMemo(() => audioRef && fragments.map(p => p.time / duration * 100),
      [audioRef, duration, fragments]);

  const setCurrentPosInPercent = useCallback((percent) => audioRef.currentTime = duration * percent,
      [audioRef, duration]);

  const playPause = useCallback(() => {
      if (audioRef && audioRef.readyState) {
          audioRef.paused ? audioRef.play() : audioRef.pause();
      }
  }, [audioRef]);

  return (
    <div className={styles.player}>
      <span className={styles.controls}>
        <span onClick={playPause} title='Play/Pause'><Icon path={mdiPlayPause} size={1}/></span>
        <span onClick={e => addMarker(currentPos)} title='Add marker'>
            <Icon path={mdiPlusCircle} size={1}/>
        </span>
        <span className={styles.duration}>
            {Duration.fromMillis((currentPos || 0) * 1000).toFormat('mm:ss')} /
            {Duration.fromMillis((duration || 0) * 1000).toFormat('mm:ss')}
        </span>
        <strong className={styles.title}>{name}</strong>
        {buttons}
      </span>
      <Timescale cursorPos={audioRef && currentPos / duration * 100}
                 setCurrentPos={setCurrentPosInPercent}
                 fragmentClickHandler={fragmentClickHandler}
                 points={pointsInPercents}
                 duration={duration || 0}/>
      {project.is_video ?
          <div style={{display: "flex", justifyContent: "center"}}><video src={project.src} ref={setAudioRef} style={{height: "25rem"}}/></div> :
          <audio src={project.src} ref={setAudioRef}/>}
    </div>
  );
};

Player.propTypes = {
    fragments: PropTypes.arrayOf(PropTypes.object),
    setFragments: PropTypes.func,
    selectedFragment: PropTypes.number,
    setSelectedFragment: PropTypes.func,
};

Player.defaultProps = {
};

import {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {every, over} from 'lodash';
import {useDrag, useDrop} from "react-dnd";

import styles from './timescale.module.css';
import {Duration} from "luxon";


const useMousePosition = (el) => {
  const [mousePosition, setMousePosition] = useState({x: null, y: null});

  const updateMousePosition = useCallback(event => {
    if (el) {
      const rect = el.getBoundingClientRect();
      if (event.target === el || every([
        event.clientX > rect.left,
        event.clientX < rect.right,
        event.clientY > rect.top,
        event.clientY < rect.bottom
      ])) {
        setMousePosition({x: event.clientX - rect.left, y: event.clientY - rect.top});
      }
    } else {
      setMousePosition({x: event.clientX, y: event.clientY});
    }
  }, [el]);

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, [updateMousePosition]);

  return mousePosition;
};


export const Timescale = ({points, cursorPos, setCurrentPos, fragmentClickHandler, duration, ...props}) => {
  const [scaleRef, setScaleRef] = useState();
  const {x} = useMousePosition(scaleRef);

  const rect = useMemo(() => scaleRef && scaleRef.getBoundingClientRect(), [scaleRef]);
  const cursorStyle = useMemo(() => rect && {left: rect.left + rect.width * cursorPos / 100, top: rect.top},
      [rect, cursorPos]);
  const onClickHandler = useCallback((e) => rect && setCurrentPos((e.clientX - rect.left) / rect.width),
      [rect, setCurrentPos]);

  const [{opacity}, dragRef] = useDrag(() => ({
      type: "cursor",
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      })
    }),
    []);

  const [{isOver}, setDropRef] = useDrop(() => ({
    accept: "cursor",
    drop: (item, monitor) => {
      rect && setCurrentPos((monitor.getClientOffset().x - rect.left) / rect.width);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }), [setCurrentPos, rect]);

  return (
    <div className={styles.scale} ref={over([setScaleRef, setDropRef])} onClick={onClickHandler}
             data-tip
             data-for='timePos'>
      <ReactTooltip key={x} id='timePos' type='info' palce='top' overridePosition={(pos) => ({left: pos.left, top: 15})}>
            <span>{x && rect && Duration.fromMillis(duration * x / rect.width * 1000).toFormat('mm:ss')}</span>
          </ReactTooltip>
      {points.map((p, i) =>
        <div key={i}
             className={styles.fragment}
             onClick={e => fragmentClickHandler(i)}
             style={{left: `${p}%`, width: `${(i < points.length - 1 ? points[i+1] : 100) - p}%`}} >

        </div>)}
        {cursorPos >= 0 && <div ref={dragRef} className={styles.cursor} style={cursorStyle} />}
    </div>
  );
};

Timescale.propTypes = {
  points: PropTypes.arrayOf(PropTypes.number),
  cursorPos: PropTypes.number,
};

Timescale.defaultProps = {
  points: [0],
  cursorPos: -1,
};

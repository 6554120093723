import escapeHtml from 'escape-html';
import {Text} from 'slate';
import React from "react";
import {identity} from "lodash";


const slate2html = (node, i, key, labelTypes) => {
  key = `${key}_${i}`

  if (Text.isText(node)) {
    let val = node.text;
    const labels = Object.keys(node).map(k => labelTypes[k]).filter(identity);
    if (node.bold) {
      val = <strong key={key}>{val}</strong>
    }
    if (node.italic) {
      val = <em key={key}>{val}</em>
    }
    if (node.underline) {
      val = <u key={key}>{val}</u>
    }
    if (labels.length > 0) {
      val = <u key={key} title={labels.join(", ")}>{val}</u>
    }
    return val
  }

  const children = node?.children?.map((n, i) => slate2html(n, i, key, labelTypes))

  switch (node?.type) {
    case 'paragraph':
      return <p key={key} style={{...(node?.style || {})}}>{children}</p>
    case 'block-quote':
      return <blockquote key={key}>{children}</blockquote>
    case 'bulleted-list':
      return <ul key={key}>{children}</ul>
    case 'heading-one':
      return <h1 key={key} style={{...(node?.style || {})}}>{children}</h1>
    case 'heading-two':
      return <h2 key={key} style={{...(node?.style || {})}}>{children}</h2>
    case 'heading-three':
      return <h3 key={key} style={{...(node?.style || {})}}>{children}</h3>
    case 'list-item':
      return <li key={key}>{children}</li>
    case 'numbered-list':
      return <ol key={key}>{children}</ol>
    // case 'attribute':
    //   return attributes[node.name];
    default:
      return children
  }
}


export default slate2html;

import {Fragment, useCallback, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import * as Yup from 'yup';
import {mdiClose, mdiContentSave, mdiRobotConfused} from "@mdi/js";
import Icon from "@mdi/react";
import classNames from 'classnames';
import {useHistory} from "react-router";
import {useBeforeunload} from 'react-beforeunload';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {CircularProgressbar} from "react-circular-progressbar";
import {useQuery} from "react-query";
import {isNil} from "lodash";

import {TextTable} from "../text_table/text_table";
import {Player} from "../player/player";
import {FeatureSelect} from "./features";
import {useApiClient} from "../../api";

import 'react-circular-progressbar/dist/styles.css';
import styles from './transcriber.module.css';


const FragmentSchema = Yup.object().shape({
  uuid: Yup.string().required().default(uuidv4),
  time: Yup.number().required(),
  speaker: Yup.string(),
  tags: Yup.array().of(Yup.string()).default([]),
});


export const Transcriber = ({src, project, recognize, save, initial, duration, name, ...props}) => {
  const [fragments, setFragments] = useState([FragmentSchema.cast({time: 0})]);
  const [features, setFeatures] = useState([]);
  const [selectedFragment, setSelectedFragment] = useState();
  const api = useApiClient();

  const history = useHistory();

  const progress = useQuery(["project", project.id, "progress"], () => api.get(`/api/project/${project.id}/progress`),
      {enabled: !isNil(project.process_id), refetchInterval: 5000}).data?.data ?? 0;

  useEffect(() => {
      initial?.fragments && setFragments(initial.fragments);
      initial?.features && setFeatures(initial.features);
  }, [initial]);

  useEffect(() => progress == 100 && api.queryClient.invalidateQueries(['project', project.id]), [progress]);

  const addMarker = useCallback((currentPos) => {
      if (currentPos) {
          const index = fragments.concat({time: duration}).findIndex(p => p.time > currentPos) - 1;
          setFragments([
              ...fragments.slice(0, index + 1),
              FragmentSchema.cast({time: currentPos}),
              ...fragments.slice(index + 1)
          ]);
      }
  }, [fragments, setFragments]);

    const isDirty = initial?.fragments !== fragments || initial?.features !== features;

    useBeforeunload(event => isDirty && event.preventDefault());

    return (
      <DndProvider backend={HTML5Backend}>
      <div className={styles.transcriber}>
          <Player project={project}
                  fragments={fragments}
                  duration={duration}
                  name={name}
                  addMarker={addMarker}
                  selectedFragment={selectedFragment}
                  setSelectedFragment={setSelectedFragment}
                  buttons={[
                    isNil(project.process_id) ? <span key={1}
                            className={classNames(styles.button)}
                            onClick={() => window.confirm("Применить автоматическое распознавание? Текущая разметка будет удалена.") && recognize()}>
                        <Icon path={mdiRobotConfused} size={1.2}/>
                      </span> : <div key={2} style={{ width: 42, height: 42 }}>
                        <CircularProgressbar value={progress} text={`${progress.toFixed(0)}%`} />
                      </div>,
                      <span key={3}
                            className={classNames(styles.button, {[styles.unsaved]: isDirty})}
                            onClick={() => save({fragments, features})}>
                        <Icon path={mdiContentSave} size={1.2}/>
                      </span>,
                      <span key={4}
                            className={styles.button}
                            onClick={() => (!isDirty || window.confirm("Несохранённые изменения будут потеряны!")) && history.goBack()}>
                        <Icon path={mdiClose} size={1.2}/>
                      </span>
                  ]}
          />
          <FeatureSelect features={features}
                         setFeatures={setFeatures} />
          <div className={styles.table}>
              <TextTable fragments={fragments}
                         setFragments={setFragments}
                         selectedFragment={selectedFragment}/>
          </div>
      </div>
      </DndProvider>
  );
};

Transcriber.propTypes = {
    src: PropTypes.string.isRequired,
};

Transcriber.defaultProps = {
};

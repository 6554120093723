import React, {useCallback, useContext, useEffect, useState} from 'react';
import {isNil, isNull} from 'lodash';
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import Gravatar from 'react-gravatar';

import {useApiClient} from './api';


export const CurrentUserContext = React.createContext();


export const CurrentUserProvider = ({children}) => {
    const api = useApiClient();
    const history = useHistory();
    const [user, setUser] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        setToken(localStorage.getItem('access_token'));
    }, []);

    useEffect(() => {
        try {
            if (token) {
                localStorage.setItem('access_token', token);
                api.get('/api/user/me').then(resp => setUser(resp.data));
            }
            else if (isNil(token)) {
                setUser(null);
            }
        } catch (e) {
            setUser(null);
        }
    }, [token]);

    const logout = useCallback(() => {
        localStorage.removeItem('access_token');
        history.push('/signin');
        setToken(undefined);
        toast.info("До свидания!");
    }, [history, setToken])

    return (
        <CurrentUserContext.Provider value={{user, setUser, token, setToken, logout}}>
            {children}
        </CurrentUserContext.Provider>
    );
};


export const useCurrentUser = (allow_unauthorized=false) => {
    const history = useHistory();
    const {user, token} = useContext(CurrentUserContext);

    if (!allow_unauthorized && isNull(token)) {
        history.push('/signin');
        toast.warn('Необходимо выполнить вход!');
    }

    return user;
}


export const UserInlineView = ({user, show_avatar=true, show_login=true, size=20}) => {
    return (
        <span style={{display: 'block'}}>
            {user && show_avatar && <Gravatar email={user.email} size={size} />}
            {user && show_login && user.username}
        </span>
    )
}

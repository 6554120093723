import styles from './text_table.module.css';

import {useEffect, useMemo, useRef} from "react";
import PropTypes from 'prop-types';
import {isUndefined, noop, uniq} from 'lodash';

import {Row} from "./text_table_row";


export const TextTable = ({fragments, setFragments, selectedFragment, ...props}) => {
  const body = useRef();

  useEffect(() => {
      if (!isUndefined(selectedFragment)) {
          body.current.children[selectedFragment].scrollIntoView(true);
      }
  }, [selectedFragment]);

  const updateFragmentHandler = (i, f) => setFragments(fragments.slice(0, i)
      .concat([{...fragments[i], ...f}])
      .concat(fragments.slice(i + 1)));
  const mergeUp = (i) => setFragments(fragments.slice(0, i - 1)
      .concat([{
        ...fragments[i - 1],
        tags: uniq([...fragments[i - 1].tags, ...fragments[i].tags]),
        text: [...fragments[i - 1].text, ...fragments[i].text],
      }])
      .concat(fragments.slice(i + 1)));

  const createOption = (label) => ({
    label,
    value: label.toLowerCase(),
  });

  const speakerOptions = useMemo(() => uniq(fragments.filter(f => f.speaker).map(f => f.speaker)).map(createOption),
      [fragments]);
  const tagsOptions = useMemo(() => uniq(fragments.map(f => f.tags).flat()).map(createOption), [fragments]);

  return (
    <div className={styles.text_table}>
      <div className={styles.head}>
        <div>Time</div>
        <div>Speaker</div>
        <div>Transcription</div>
        <div>Tags</div>
      </div>
      <div className={styles.body} ref={body}>
      {fragments.map((fragment, i) => <Row key={fragment.uuid}
                                           isFirst={i === 0}
                                           mergeUp={() => i > 0 ? mergeUp(i) : noop}
                                           fragment={fragment}
                                           updateFragment={f => updateFragmentHandler(i, f)}
                                           speakerOptions={speakerOptions}
                                           tagsOptions={tagsOptions}/>)}
      </div>
    </div>
  );
};

TextTable.propTypes = {
  fragments: PropTypes.arrayOf(PropTypes.object),
};

TextTable.defaultProps = {
};

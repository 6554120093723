import {useQuery} from "react-query";
import {useContext} from "react";
import {Link} from "react-router-dom";

import styles from "./groups.module.css";

import {useApiClient} from "../../api";
import {CurrentUserContext, useCurrentUser} from "../../user";


export const Groups = ({...props}) => {
    const api = useApiClient();
    const {user, logout} = useContext(CurrentUserContext);

    const userGroupsQuery = useQuery('groups', () => api.get('/api/group').then(resp => resp.data));
    const userGroups = userGroupsQuery.data || [];

    const createGroup = () => {
        const name = prompt("Enter group name");
        name && api.post("/api/group", {group_name: name})
            .then(() => window.location.reload())
    }

    const deleteGroup = (id) => {
        if (window.confirm("Вы уверены что хотите удалить группу (удалить можно только пустую группу)?")) {
            api.delete(`/api/group/${id}`).then(() => window.location.reload())
        }
    }

    return (
        <div>
            <header className={styles.header}>
                <span>Группы</span>
                <button className={styles.btn} onClick={createGroup}>Создать группу</button>
                <button className={styles.btn} onClick={logout}>Выход</button>
            </header>
            <div className={styles.groups}>
                {user && userGroups.map((group, i) => <div key={i}>
                    <div className={styles.wrap_groups}>
                        <div key={`${group.id}+${group.group_name}`} className={styles.group_name}>
                            <Link to={`/groups/${group.id}`}>
                                <div>{group.group_name}</div>
                            </Link>
                            <span className={styles.hint}>{group.owner?.id === user.id ? "Администратор" : "Участник"}</span>
                        </div>
                        {group.owner?.id === user.id
                            ? <button className={styles.btn} onClick={() => deleteGroup(group.id)}>&#128465;</button>
                            : ''}
                    </div>
                    <hr/>
                </div>)}
            </div>
        </div>
    )
}
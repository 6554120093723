import React from 'react';
import {useQuery} from "react-query";
import {Fragment, useMemo, useState} from "react";
import Select from "react-select";
import {fromPairs, isUndefined} from "lodash";
import Modal from 'react-modal';

import styles from "./corpora.module.css";

import {useApiClient} from "../../api";
import slate2html from "../editor/serialize";


const customStyles = {
  content: {
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');


const Help = () => {
  const [modalId, setModalId] = React.useState();
  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setModalId(undefined);
  }

  const title = {
    1: "О корпусе",
    2: "Система помет",
    3: "Поиск по корпусу",
    4: "Выдача результатов поиска",
  }

  return (
      <div style={{display: "flex", "gap": "2rem"}}>
        <span onClick={() => setModalId(1)}>О корпусе</span>
        <span onClick={() => setModalId(2)}>Система помет</span>
        <span onClick={() => setModalId(3)}>Поиск по корпусу</span>
        <span onClick={() => setModalId(4)}>Выдача результатов поиска</span>
        <Modal
            isOpen={!isUndefined(modalId)}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{title[modalId]}</h2>
          <button onClick={closeModal}>Закрыть</button>
          {modalId === 1 && <div>
            <p>Звуковой корпус региолектной речи создан при в рамках гранта РФФИ, проект № 19-412-590001 р_а «Вариативность региолекта: территориальный, социальный и когнитивный аспекты».</p>
            <p>В настоящее время корпус включает речь только одного из регионов России – Пермского края. В нем представлены аудиозаписи и тексты 48 интервью с жителями двух городов – Соликамска и Кунгура (общее время звучания – более 17 часов).</p>
            <p>Соликамск – город на севере Пермского края. Первое упоминание о городе относится к 1430&nbsp;году. Вся история Соликамска связана с горной промышленностью. В XV–XVI&nbsp;вв. в городе процветали соляные промыслы, в конце XVII – начале XVIII&nbsp;в. он становится крупнейшим солеваренным центром России. Большое значение в хозяйственно-торговом развитии Соликамска сыграло и его расположение – в XVII в. это был значимый транзитный и перевалочный пункт на пути в Сибирь. Современный хозяйственный профиль города определяют горнохимическая (добыча калийных солей и производство минеральных удобрений) и металлургическая промышленность (производство магния, основанное на переработке калийно-магниевых солей), а также предприятия лесной и военной отрасли («Уралкалий», «Соликамский магниевый завод», «Соликамскбумпром», «Соликамский завод “Урал”»). Население города составляет около 100 тысяч человек. В этническом составе большинство представлено русскими (89,8%), но высока также доля коми-пермяков (около 2%): представители коми-пермяцкого этноса являются коренными жителями региона.</p>
            <p>Кунгур – город на юге Пермского края. Основан в 1648&nbsp;г. В XIX&nbsp;в. Кунгур стал одним из крупнейших центров оптовой торговли чаем (через город проходил «Великий чайный путь», по которому чай из Китая доставлялся в Европу и Америку). Исторически это был крупный торговый и ремесленный центр; на крупнейших кунгурских ярмарках и базарах можно было приобрести такие редкости, как среднеазиатские специи и китайский фарфор. В настоящее время ремесленные традиции в городе продолжают развиваться. Кунгур до сих пор сохранил свою значимость как один из культурно-исторических центров Пермского края. На его территории располагаются геологические и природные памятники, среди которых Кунгурская ледяная пещера, входящая в десятку самых крупных пещер мира, что обеспечивает приток в город туристов. Промышленность в настоящее время представлена, фабрикой по производству стройматериалов «KNAUF Кунгур», мясокомбинатом и молочным комбинатом. Население Кунгура сейчас составляет около 65&nbsp;тыс.&nbsp;чел.; более всего среди этносов русских (87%), высока также доля татар и башкир (около 6%).</p>
            <p>Корпус направлен на рассмотрение региолектной речи не только в ее территориальном, но и в социальном варьировании. Поэтому представленные в корпусе тексты внутри каждого рассмотренного города сбалансированы по таким социальным характеристикам их авторов, как «пол», «уровень образования» (высшее/среднее), «специальность» (гуманитарная/негуманитарная) образования и «возраст» (1 возрастная группа – 25–34 года / вторая возрастная группа – 35–44 года / третья возрастная группа – 45–55 лет). Граф балансировки информантов для каждого из городов представлен на рисунке.</p>
            <img src="/help1.png" />
            <p>Представленные в корпусе тексты – это полуструктурированные интервью, включающие вопросы о городе (свободный рассказ, интересное в городе, городские объекты, достопримечательности, занятия жителей города, промышленность, реки, природа, климат, известные люди, история города, оценка города), а также свободный рассказ о себе и блок вопросов, касающихся оценки речи в городе. В корпусе материал организован по фрагментам интервью а также размечен по социальным параметрам говорящих, кроме того, корпус включает лингвистические пометы. Принципиальным отличием данного корпуса является возможность прослушать фрагмент текста, содержащий какие-либо региолектные черты, прямо со страницы выдачи результатов поиска.</p>
            <p>Подготовки и индексация текстов для корпуса звучащей региолектной речи осуществлялась в специально созданном для этой цели программе Transcriptor (<a href="https://transcriber.semograph.com">https://transcriber.semograph.com</a> – требуется регистрация). Корпус имеет возможность пополнения. Предполагается, что в будущем он может быть дополнен как материалами других городов Пермского края, так и материалами других регионов.</p>
            <p>Корпус является свободно распространяемым продуктом, вход в корпус и поиск по корпусу осуществляется по ссылке <a href="https://transcriber.semograph.com/corpora">https://transcriber.semograph.com/corpora</a> (регистрация не требуется).</p>
          </div>}
          {modalId === 2 && <div>
            Система помет, используемых в корпусе, включает металингвистические и лингвистические пометы.
            <p><strong>Металингвистические пометы</strong> включают:</p>
            <p>1. <strong>Социобиологические характеристики</strong> информантов. Возможен выбор по следующим характеристикам:</p>
            <ul>
              <li>город проживания информанта – Кунгур или Соликамск;</li>
              <li>пол – женщина или мужчина;</li>
              <li>образование – высшее или среднее;</li>
              <li>специальность – гуманитарная или негуманитарная;</li>
              <li>возрастная группа – 1 (23–34 года), 2 (35–44 года) или 3 (45–55 лет);</li>
              <li>возраст – возраст говорящего (полных лет).</li>
            </ul>
            <p>2. <strong>Теги</strong> – пометы, соответствующие теме фрагмента текста. В поле выбора предоставляется выбор из 16 тем (по алфавиту):</p>
            <ul>
              <li>важные объекты;</li>
              <li>город;</li>
              <li>достопримечательности;</li>
              <li>занятия горожан;</li>
              <li>интересное в городе;</li>
              <li>интересные горожане;</li>
              <li>история города;</li>
              <li>климат;</li>
              <li>любовь/нелюбовь к городу;</li>
              <li>о себе;</li>
              <li>отличия языка;</li>
              <li>отношение к языку;</li>
              <li>природа;</li>
              <li>промышленность;</li>
              <li>реки;</li>
              <li>узнаваемость языка.</li>
            </ul>
            <p><strong>Лингвистические пометы</strong> включают следующие (с использованными сокращениями):</p>
            <ul>
              <li>грамматика – отступления от грамматической нормы литературной речи при реализации морфологической структуры слова;</li>
              <li>дискурсив – дискурсивные слова (дискурсивы) – «незнаменательные слова или словосочетания, регулирующие дискурсивный процесс между говорящим и адресатом», функции которых «находятся в сфере организации и регулирования дискурсивного потока» [Кибрик: электронный ресурс];</li>
              <li>книжн – книжная лексика, слова и выражения, не характерные для устной речи, но использующиеся в книжных стилях письменной речи;</li>
              <li>ненормативн – ненормативная лексическая сочетаемость, нарушения нормативной синтагматической семантики;</li>
              <li>окказион – окказиональная лексика (окказионализмы), слова, созданные говорящим в процессе речевой деятельности по существующим в языке словообразовательным моделям или стандартным семантическим переносам;</li>
              <li>простореч – просторечная лексика, слова и выражения, находящиеся вне рамок литературного языка;</li>
              <li>разг – разговорная лексика, слова и выражения, которые употребляются носителями литературного языка в неформальной обстановке;</li>
              <li>региолект – региолектная лексика (регионализмы), слова и выражения, либо используемые только в конкретном регионе, либо используемые в конкретном регионе чаще, чем в других;</li>
              <li>синтаксис – отступления от грамматической нормы литературной речи при реализации синтаксических конструкций;</li>
              <li>спец – специальная лексика, слова и выражения, употребляющиеся в какой-то специальной сфере и не являющиеся общеупотребительными (данная помета присваивается терминам и профессионализмам);</li>
              <li>устар – устаревшая лексика, слова и выражения, вышедшие из активного употребления (помета присваивается архаизмам и историзмам);</li>
              <li>фонетика – отступления от литературной фонетической реализации.</li>
            </ul>
          </div>}
          {modalId === 3 && <div>
            <p>Для работы с корпусом пермской региолектной речи перейдите по ссылке <a href="https://transcriber.semograph.com/corpora">https://transcriber.semograph.com/corpora</a>. Регистрация не требуется.</p>
            <p>При входе в корпус Вы увидите окно поиска:</p>
            <img src="/help2.png" width="100%" />
            <p>Здесь Вы можете задать следующие параметры поиска: Социобиологические характеристики, Теги (темы фрагмента), Пометы, Текст (конкретное слово / фразу).</p>
            <p>Для выбора параметра поиска нажмите на знаки выпадающего списка слева <img src="/help3.png" height="20" /> или справа <img src="/help4.png" height="20" />	в поле поиска; открывшийся список прокрутите до нужной характеристики и выберите ее, нажав правой кнопкой мыши.</p>
            <img src="/help5.png" width="100%" />
            <p>Для отмены выбора параметра поиска нажмите на крестик над ним. Для сброса всех параметров нажмите на крестик справа в поле поиска.</p>
            <p>Поля для выбора параметров поиска независимы, поэтому их можно произвольно комбинировать или оставлять некоторые пустыми.</p>
            <p>Для полнотекстового поиска конкретных единиц введите в поле Текст искомое слово / словосочетание / фразу. Поиск чувствителен к регистру, возможен поиск только полного вхождения, поэтому для получения точных результатов необходимо вводить не лемму, а словоформу.</p>
          </div>}
          {modalId === 4 && <div>
            <p>Выдача представляет собой таблицу, где отражено количество найденных фрагментов интервью, содержащих</p>
            <ul>
              <li>искомую единицу (№);</li>
              <li><strong>Социобиологические характеристики</strong> говорящего;</li>
              <li><strong>Теги</strong> – те тематические фрагменты интервью, в которых встречается искомая единица;</li>
              <li><strong>Контекст</strong> – транскрипт фрагмента текста, в котором найдена искомая единица;</li>
              <li><strong>Фрагмент</strong>, где отражается встроенный аудиоплейер для прослушивания фрагмента аудиозаписи.</li>
            </ul>
            <p>Искомая единица в затранскрибированном фрагменте обозначена подчеркиванием: <u>здорово</u>, <u>немножко</u> и др. (см. выше). При наведении на нее курсора отражается лингвистическая помета, присвоенная единице.</p>
            <p>Над таблицей выдачи результатов в поле Найдено отражается абсолютное количество найденных единиц.</p>
            <p><strong>Интерпретация транскрипта</strong></p>
            <p>В поле Контекст представлена письменная фиксация устной речи в соответствии с принципами, широко применяемыми в исследованиях устной речи [Китайгородская и др. 1995; Русская разговорная речь 1978 и др.]. Переданы границы фразы (//) и синтагмы (/), знак ударения (′) поставлен в тех случаях, когда в речи информанта наблюдалось отклонение от литературной нормы или это необходимо для различения форм слова; в круглых скобках передавались невербальные реакции информантов (смех), а также части недоговоренных слов: може(т), в крадратных скобках показаны ненормативные фонетические реализации: о[γ]ород.</p>
            <p><strong>Управление аудиоплейером</strong></p>
            <p>Аудиоплейер имеет стандартный интерфейс. При нажатии на кнопку «Проиграть» начнется проигрывание аудиозаписи, при повторном нажатии проигрывание будет остановлено («Пауза»). Вы можете выбирать фрагмент аудиозаписи с помощью перемещения ползунка на полосе прокрутки.</p>
            <img src="/help6.png" />
            <p>При нажатии на кнопку «Громкость» <img src="/help8.png" height="20" /> с помощью полосы прокрутки можно выбирать громкость воспроизведения.</p>
            <p>При нажатия на три вертикальные точки <img src="/help9.png" height="20" /> откроется доступ к дополнительным возможностям аудиоплейера: изменению скорости воспроизведения и сохранению аудиофайла.</p>
            <img src="/help7.png" />
          </div>}
        </Modal>
      </div>
  )
}


export const Corpora = ({...props}) => {
    const api = useApiClient();

    const [params, setParams] = useState({});

    const createOption = (label) => ({
      label,
      value: label,
    });

    const optionsQuery = useQuery('options', () => api.get('/api/corpora/options').then(resp => resp.data));
    const options = useMemo(() => ({
      features: (optionsQuery.data?.features || []).map(createOption),
      tags: (optionsQuery.data?.tags || []).map(createOption),
      labels: (optionsQuery.data?.labels || []).map(createOption),
    }), [optionsQuery.data]);

    const labelsQuery = useQuery(['label'], () => api.get(`/api/label`));
    const labelTypes = fromPairs((labelsQuery.data?.data || []).filter(lt => (params.labels || []).includes(lt.name))
        .map(lt => [lt.uuid, lt.name]));

    const resultQuery = useQuery(["result", params], () => api.get('/api/corpora/search', {params}).then(resp => resp.data));

    const count = (resultQuery.data ?? []).map(r => Object.keys(labelTypes)
        .map(lt => (JSON.stringify(r.content).match(new RegExp(lt, "g")) || []).length)
        .reduce((partialSum, a) => partialSum + a, 0))
        .reduce((partialSum, a) => partialSum + a, 0);

    return (
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <span>Корпус</span>
            <Help />
          </header>
          <div className={styles.form}>
            <div>Социобиологические характеристики</div>
            <Select isMulti placeholder="..." options={options.features}
                    onChange={v => setParams({...params, features: v.map(o => o.value)})} />

            <div>Теги</div>
            <Select isMulti placeholder="..." options={options.tags}
                    onChange={v => setParams({...params, tags: v.map(o => o.value)})} />

            <div>Пометы</div>
            <Select isMulti placeholder="..." options={options.labels}
                    onChange={v => setParams({...params, labels: v.map(o => o.value)})} />

            <div>Текст</div>
            <input type="text" onChange={e => setParams({...params, text: e.target.value})} />

            <div>Найдено</div>
            <div>{count} единиц / {(resultQuery?.data ?? []).length} контекстов</div>
          </div>

          <div className={styles.results}>
            <div className={styles.sub_header}>№</div>
            <div className={styles.sub_header}>Социобиологические характеристики</div>
            <div className={styles.sub_header}>Теги</div>
            <div className={styles.sub_header}>Контекст</div>
            <div className={styles.sub_header}>Фрагмент</div>

            {(resultQuery.data ?? []).map((r, i) => <Fragment key={i}>
              <div>{i+1}</div>
              <div>{r.features.join(", ")}</div>
              <div>{r.tags.join(", ")}</div>
              <div className={styles.content}>
                {r.content?.map((node, j) => slate2html(node, j, "", labelTypes))}
              </div>
              <div><audio controls controlsList="nodownload" preload="none" src={`${r.src}#t=${r.time},${r.end}`} /></div>
            </Fragment>)}
          </div>
        </div>
    )
}

import {useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {useMutation, useQuery} from "react-query";
import {useHistory, useParams} from "react-router";
import {Link} from "react-router-dom";
import {ValidationError} from "yup";
import {toast} from "react-toastify";

import {useApiClient} from "../../api";
import {CurrentUserContext, useCurrentUser} from "../../user";

import styles from './project_list.module.css';


function NewProjectFromFile({group_id}) {
  const inputRef = useRef();
  const api = useApiClient();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createProjectURL = async () => {
      const src = prompt("Source file url");
      if (src !== "") {
          setIsSubmitting(true);
          try {
            const data = new FormData();
            data.set("src", src);
            data.set("group_id", group_id);
            const resp = await api.post(`/api/project?group_id=${group_id}`, data, {
              onUploadProgress: e => console.log(Math.round((e.loaded * 100) / e.total))
            });
            await api.queryClient.invalidateQueries(['project']);
            history.push(`/projects/${resp.data.id}`);
          } catch (err) {
              if (err instanceof ValidationError) {
                  toast.warn(JSON.stringify(err.errors))
              }
          }
          setIsSubmitting(false);
      }
  }

  const createProject = async (e) => {
    if (e.target.files.length > 0) {
      setIsSubmitting(true);
      const data = new FormData();
      data.set("file", e.target.files[0]);
      data.set("group_id", group_id);
      const resp = await api.post(`/api/project?group_id=${group_id}`, data, {
        onUploadProgress: e => console.log(Math.round((e.loaded * 100) / e.total))
      });
      await api.queryClient.invalidateQueries(['project']);
      setIsSubmitting(false);
      history.push(`/projects/${resp.data.id}`);
    }
  }

  return <div style={{display: "flex", gap: "1rem"}}>
    {isSubmitting ? "Загрузка..." : <>
      {/*<button className={styles.btn} disabled={isSubmitting} onClick={createProjectURL}>Создать проект по URL</button>*/}
      <button className={styles.btn} disabled={isSubmitting} onClick={() => inputRef.current.click()}>Создать проект из файла</button>
    </>}
    <input type="file" accept="audio/*,video/*" style={{display: "none"}} ref={inputRef} onChange={createProject} />
  </div>;
}


export const ProjectList = ({...props}) => {
  const api = useApiClient();
  const {user, logout} = useContext(CurrentUserContext);
  const {group_id} = useParams();

  const currentGroupQuery = useQuery(['group'], () => api.get(`/api/group/${group_id}`));
  const currentGroup = currentGroupQuery.data?.data || {};

  const projectsQuery = useQuery(['project'], () => api.get(`/api/project/group/${group_id}`));
  const projects = projectsQuery.data?.data || [];

  const userGroupsQuery = useQuery(['userGroups'], () => api.get(`/api/uig`));
  const userGroups = userGroupsQuery.data?.data.map(gr => gr["group_id"]) || [];

  const membersQuery = useQuery(['members'], () => api.get(`/api/uig/members?group_id=${group_id}`));
  const members = membersQuery.data?.data.map(gr => gr["user_id"]) || [];

  const allUsersQuery = useQuery(['users'], () => api.get('/api/user'));
  const allUsers = allUsersQuery.data?.data || [];

  const owner = allUsers.find((el) => (el.id === currentGroup.owner?.id ? el : '')) || {};

  const memberUsers = [];

  for (let i=0; i<members.length; i++) {
      for (let j=0; j<allUsers.length; j++) {
          if (allUsers[j].id === members[i]) {
          memberUsers.push(allUsers[j])
        }
      }
    }

  const addUser = () => {
      const email = prompt("Enter user email");
      if (email !== "") {
          api.get("/api/user").then(resp => {
              for (let i=0; i < resp.data.length; i++) {
                  if (resp.data[i]["email"] === email) {
                      api.post("/api/uig", {user_id: resp.data[i]["id"], group_id}).
                      then(() => window.location.reload())
                  }
              }
          });
      }
  }

  const deleteUser = (id) => {
      if (window.confirm('Вы действительно хотите удалить пользователя из группы?')) {
          id !== currentGroup.owner?.id
              ? api.delete(`/api/uig/${id}`, {user_id: id, group_id: currentGroup.id}).then(() => window.location.reload())
              : toast.warn("Вы не можете удалить владельца группы")
      }
  }

  const deleteProject = (id) => {
      if (window.confirm('Вы действительно хотите удалить проект?') === true) {
          api.delete(`/api/project/${id}`, {group_id: currentGroup.id}).then(() => window.location.reload());
      }
  }
    
  return (
      <div className={styles.project_list}>
          {userGroups.includes(currentGroup.id)
              ? <>
                <div className={styles.projects}>
                  <p>Проекты <Link to="/groups">группы</Link> "{currentGroup.group_name}"</p>
                  <hr/>
                  <NewProjectFromFile group_id={group_id} />
                  <ul>
                    {projects.map(p => <li key={p.id}>
                      <Link to={`/projects/${p.id}`}>&#8250;&emsp;{p.name}</Link>
                      {user && (p.owner === user.id || user.id === currentGroup.owner?.id) ?
                          <button className={styles.btn} onClick={() => deleteProject(p.id)}>&#128465;</button> : ''}
                    </li>)}
                  </ul>
                </div>
                <div className={styles.buttons}>
                  {user?.id === currentGroup.owner?.id ? <>
                    <button className={styles.btn} onClick={addUser}>&nbsp;Добавить участников&nbsp;</button>
                  </> : ''}
                  <div>Вы: {user && user.username}</div>
                  <button className={styles.btn} onClick={logout}>Выход</button>
                </div>
                <div className={styles.members}>
                  <div className={styles.members_title}>Участники группы
                    <div>Администратор: {owner.username}</div>
                  </div>
                  <hr/>
                  {memberUsers.map(member => <div key={member.id}>
                        {currentGroup.owner?.id !== member.id
                            ? <>
                              <div className={styles.user_list}>
                                <div>{member.username}</div>
                                {user && (currentGroup.owner?.id === user.id || member.id === user.id)
                                    ?
                                    <button className={styles.btn} onClick={() => deleteUser(member.id)}>&#128465;</button>
                                    : ''}
                              </div>
                              <hr/>
                            </>
                            : ''}
                      </div>
                  )}
                </div>
              </>
              : ''}
      </div>
  );
};

ProjectList.propTypes = {
};

ProjectList.defaultProps = {
};

import React, {useContext, useEffect} from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {Link, useHistory, useLocation} from 'react-router-dom';
import qs from "query-string";

import {useApiClient} from "./api";
import {toast} from "react-toastify";
import {CurrentUserContext, useCurrentUser} from "./user";
import {ErrorView} from "./form_fields";

import "./App.css";


export const SignupForm = () => {
    const history = useHistory();
    const api = useApiClient();
    const user = useCurrentUser(true);

    return (
        <>
        {user && <span>Вы уже выполнили вход!</span>}
        {!user && <Formik
            initialValues={{username: '', full_name: '', email: ''}}
            validationSchema={Yup.object({
                username: Yup.string()
                    .max(30, 'Должно быть не более 30 символов')
                    .required('Заполните поле'),
                full_name: Yup.string()
                    .max(50, 'Должно быть не более 50 символов')
                    .required('Зполните поле'),
                email: Yup.string()
                    .email('Некорректный email')
                    .required('Заполните поле'),
                password: Yup.string()
                    .min(6, 'Должно быть не менее 6 символов')
                    .max(20, 'Долно быть не более 20 символов')
                    .required('Заполните поле'),
                passwordConfirmation: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
            })}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                api.post('/api/user', values)
                    .then((resp) => {
                        setSubmitting(false);
                        history.push('/signin');
                        toast.success("Вы успешно зарегистрированы! Пожалуйста, подтвердите свой email, пройдя по "+
                            "ссылке из только что отправленного письма.");
                    })
                    .catch(err => {
                        if (err.response.status == 400 && err.response.data.detail) {
                            setErrors(err.response.data.detail);
                        }
                    });
            }}
        >
            <Form className='login'>
                <h3>Регистрация</h3>

                <label htmlFor='username'>Логин</label>
                <Field name='username' type='text'/>
                <ErrorMessage component={ErrorView} name='username'/>

                <label htmlFor='full_name'>ФИО</label>
                <Field name='full_name' type='text'/>
                <ErrorMessage component={ErrorView} name='full_name'/>

                <label htmlFor='email'>Email</label>
                <Field name='email' type='email'/>
                <ErrorMessage component={ErrorView} name='email'/>

                <label htmlFor='password'>Пароль</label>
                <Field name='password' type='password'/>
                <ErrorMessage component={ErrorView} name='password'/>

                <label htmlFor='passwordConfirmation'>Подтверждение пароля</label>
                <Field name='passwordConfirmation' type='password'/>
                <ErrorMessage component={ErrorView} name='passwordConfirmation'/>

                <button type='submit' className="btn">Отправить</button>

                <Link to='/signin'>Вход</Link>
            </Form>
        </Formik>}
        </>
    );
};


export const SigninForm = () => {
    const history = useHistory();
    const api = useApiClient();
    const {user, setToken} = useContext(CurrentUserContext);

    return (
        <>
        {user && <span>Вы уже выполнили вход!</span>}
        {!user && <div className='login'>
            <h3>Вход</h3>

            <Formik
                initialValues={{username: '', password: ''}}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .required('Заполните поле ↑'),
                    password: Yup.string()
                        .min(6, 'Должно быть не менее 6 символов')
                        .required('Заполните поле ↑'),
                })}
                onSubmit={async (values, {setSubmitting}) => {
                    const form = new FormData();
                    form.set('username', values.username);
                    form.set('password', values.password);
                    try {
                        const resp = await api.post('/api/token', form);
                        setSubmitting(false);
                        setToken(resp.data.access_token);
                        history.push('/groups');
                        toast.success("Добро пожаловать!");
                    } catch (err) {
                        if (err.response && err.response.status === 400) {
                            toast.warn(err.response.data.detail);
                        } else if (err.response && err.response.status === 401) {
                            toast.warn("Неправильный логин/пароль!");
                        }
                    }
                }}
            >
                <Form>
                    <label htmlFor='username'>Логин</label>
                    <Field name='username' type='text'/>
                    <ErrorMessage component={ErrorView} name='username'/>

                    <label htmlFor='password'>Пароль</label>
                    <Field name='password' type='password'/>
                    <ErrorMessage component={ErrorView} name='password'/>

                    <button type='submit' className='btn'>Войти</button>

                    <Link to='/signup'>Регистрация</Link>

                    <Link to='/forgot'>Восстановить пароль</Link>
                </Form>
            </Formik>
        </div>}
        </>
    );
};


export const ForgotForm = () => {
    const history = useHistory();
    const api = useApiClient();
    const {user, setToken} = useContext(CurrentUserContext);

    return (
        <>
        {user && <span>Вы уже выполнили вход!</span>}
        {!user && <div>


            <Formik
                initialValues={{email: ''}}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email()
                        .required('Обязательно указать'),
                })}
                onSubmit={async (values, {setSubmitting}) => {
                    try {
                        const resp = await api.post('/api/user/forgot', values);
                        setSubmitting(false);
                        history.push('/');
                        toast.success("Для сброса пароля пройдите по ссылке в письме");
                    } catch (err) {
                        if (err.response && err.response.status === 400) {
                            toast.warn(err.response.data.detail);
                        }
                    }
                }}
            >
                <Form className='login'>
                    <h3>Восстановление пароля</h3>

                    <label htmlFor='email'>E-mail</label>
                    <Field name='email' type='text'/>
                    <ErrorMessage component={ErrorView} name='email'/>

                    <button type='submit' className="btn">Сбросить</button>

                    <Link to='/signin'>Вход</Link>
                    <Link to='/signup'>Регистрация</Link>
                </Form>
            </Formik>
        </div>}
        </>
    );
};


export const ResetForm = () => {
    const history = useHistory();
    const api = useApiClient();
    const user = useCurrentUser(true);
    const location = useLocation();
    const token = qs.parse(location.search).token;

    return (
        <>
        {user && <span>Вы уже выполнили вход!</span>}
        {!user && <Formik
            initialValues={{password: '', passwordConfirmation: '', token}}
            validationSchema={Yup.object({
                password: Yup.string()
                    .min(6, 'Должно быть не менее 6 символов')
                    .max(20, 'Должно быть не более 20 символов')
                    .required('Заполните поле'),
                passwordConfirmation: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
            })}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                api.post('/api/user/reset', values)
                    .then((resp) => {
                        setSubmitting(false);
                        history.push('/signin');
                        toast.success("Пароль сброшен, воспользуйтесь новым паролем для входа.");
                    })
                    .catch(err => {
                        if (err.response.status == 400 && err.response.data.detail) {
                            setErrors(err.response.data.detail);
                        }
                    });
            }}
        >
            <Form className='login'>
                <h3>Восстановление пароля</h3>
                <label htmlFor='password'>Пароль</label>
                <Field name='password' type='password'/>
                <ErrorMessage component={ErrorView} name='password'/>
                <label htmlFor='passwordConfirmation'>Подтверждение пароля</label>
                <Field name='passwordConfirmation' type='password'/>
                <ErrorMessage component={ErrorView} name='passwordConfirmation'/>

                <button type='submit' className="btn">Отправить</button>

                <Link to='/signin'>Вход</Link>
            </Form>
        </Formik>}
        </>
    );
};

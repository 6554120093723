import {Fragment, useEffect, useRef, useState} from "react";
import {identity, isNil, isUndefined} from "lodash";
import {ContextMenu as ContextMenuR, ContextMenuTrigger, SubMenu} from "react-contextmenu";

import './react-contextmenu.css';
import MenuItem from "./menuitem";

function useTextSelection() {
  const ref = useRef();

  const [range, setRange] = useState(null);

  useEffect(() => {
    function handleChange() {
      const selection = window.getSelection();

      if (
        !selection ||
        selection.isCollapsed ||
        !selection.containsNode(ref.current, true)
      ) {
        setRange(null);
        return;
      }

      setRange(selection.getRangeAt(0));
    }

    document.addEventListener("selectionchange", handleChange);
    return () => document.removeEventListener("selectionchange", handleChange);
  }, []);

  return {range, ref};
}

function renderItems(items, arrowProps) {
  return items.map(item => {
    if (item.items) {
      return (
          <SubMenu key={item.key} title={item.text}>
            {renderItems(item.items)}
          </SubMenu>
      )
    }

    return (
        <MenuItem key={item.key} onClick={item.onClick}>
          {item.text}
        </MenuItem>
    );
  });
}

export function ContextMenu({id, items, children}) {
  const {range, ref} = useTextSelection();

  return (
    <div ref={ref}>
      <ContextMenuTrigger id={id} disable={isNil(range)}>
        {children}
      </ContextMenuTrigger>
      <ContextMenuR id={id}>
        {renderItems(items(identity))}
      </ContextMenuR>
    </div>
  );
}

import {useCallback, useContext, useMemo, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {createEditor, Editor as _Editor} from 'slate';
import {Slate, Editable, withReact, useSlate} from 'slate-react';
import classNames from 'classnames';
import {mdiTrashCanOutline} from "@mdi/js";
import Icon from "@mdi/react";
import {useMutation, useQuery} from "react-query";

import {ContextMenu} from "./contextmenu";
import {useApiClient} from "../../api";

import styles from './editor.module.css';
import {ProjectContext} from "../project/project";


const Leaf = ({labelTypes, ...props}) => {
  return (
    <span {...props.attributes} className={classNames({[styles.label]: labelTypes.length > 0})}>
      {props.children}
    </span>
  )
}

const isMarkActive = (editor, format) => {
  const marks = _Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    _Editor.removeMark(editor, format)
  } else {
    _Editor.addMark(editor, format, true)
  }
}

const ClearMarkButton = ({labelType, ...props}) => {
    const editor = useSlate();
    const removeLabel = useCallback(e => {
        e.preventDefault();
        _Editor.removeMark(editor, labelType.uuid);
    }, [editor, labelType]);

    return isMarkActive(editor, labelType.uuid) ?
        <span className={styles.button}>
            {labelType.name}
            <span className={styles.icon} onMouseDown={removeLabel}>
                <Icon path={mdiTrashCanOutline} size={0.7}/>
            </span>
        </span> :
        null;
}

const Toolbar = (props) => {
  return (
    <div className={styles.toolbar}>
        {props.children}
    </div>
  );
}

export const Editor = ({uuid, value, onChange, ...props}) => {
  const editorRef = useRef(withReact(createEditor()));
  const editor = editorRef.current;

  const api = useApiClient();

  const {project} = useContext(ProjectContext);
  const group_id = project.group_id;
  const categoriesQuery = useQuery(['category', {group_id}], () => api.get(`/api/category`, {params: {group_id}}));
  const categories = categoriesQuery.data?.data || [];
  
  const labelsQuery = useQuery(['label'], () => api.get(`/api/label`));
  const labelTypes = labelsQuery.data?.data || [];

  const {mutateAsync: addCategory} = useMutation((data) => api.post(`/api/category`, {...data, group_id}), {
      onSuccess: (resp) => {
          api.queryClient.invalidateQueries(['category']);
      }
  });
  
  const {mutateAsync: addLabel} = useMutation((data) => api.post(`/api/label`, data), {
      onSuccess: (resp) => {
          api.queryClient.invalidateQueries(['label']);
      }
  });

  const items = close => categories.map(cat => ({
      key: cat.uuid,
      text: cat.name,
      items: labelTypes.filter(lt => lt.category_id === cat.uuid).map(lt => ({
          key: lt.uuid,
          text: lt.name,
          onClick: () => {
              toggleMark(editor, lt.uuid);
          }
      })).concat([{
          key: 'add_label',
          text: 'Add label',
          onClick: async () => {
            const label = prompt("New label");
            if (label && label != "") {
                const resp = await addLabel({name: label, category_id: cat.uuid});
                toggleMark(editor, resp.data.uuid);
            }
          }
      }])
  })).concat([{
      key: 'add_category',
      text: 'Add category',
      onClick: () => {
        const cat = prompt("New category name");
        if (cat && cat != "") {
            addCategory({name: cat});
        }
      }
  }]);

  const renderLeaf = useCallback(props => {
    return <Leaf labelTypes={labelTypes.filter(lt => props.leaf[lt.uuid])} {...props} />;
  }, [labelTypes]);

  return (
    <div className={styles.editor}>
      <ContextMenu items={items} id={uuid}>
        <Slate editor={editor} value={value} onChange={onChange}>
          <Toolbar>
            {labelTypes.map(lt => <ClearMarkButton key={lt.uuid} labelType={lt}/>)}
          </Toolbar>
          <Editable className={styles.content} renderLeaf={renderLeaf}/>
        </Slate>
      </ContextMenu>
    </div>
  );
};

Editor.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  labelTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Editor.defaultProps = {
  value: [{
    type: 'paragraph',
    children: [{text: ''}],
  }],
  labelTypes: [],
};
